/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex, Link } from 'theme-ui';
import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import LoadingSkeleton from 'components/common/LoadingSkeleton';

interface Props {
  children: ReactNode;
  left: string;
  right?: string;
  url?: string;
  leftUrl?: string;
  loading?: boolean;
}

const SidebarCard = ({
  left,
  leftUrl,
  url,
  right,
  children,
  loading,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const { push } = useRouter();

  const handleNavigate = () => {
    if (leftUrl || url) {
      push(leftUrl || url);
    }
  };

  return (
    <div
      sx={{
        variant: 'containers.box',
        bg: 'textWhite',
        p: 3,
        mb: 3,
        flex: 1,
      }}
    >
      <Flex
        sx={{
          mb: 3,
          fontSize: 3,
          justifyContent: 'space-between',
          '&:hover': {
            cursor: leftUrl ? 'pointer' : 'initial',
          },
          a: {
            color: 'inherit',
            textDecoration: 'none',
            '&:hover': {
              opacity: 0.7,
            },
          },
        }}
      >
        <div sx={{ fontWeight: 400 }} onClick={handleNavigate}>
          {!loading ? t(left) : <LoadingSkeleton width="70px" />}
        </div>
        {loading ? (
          <LoadingSkeleton width="90px" />
        ) : (
          url && <Link href={url}>{right || t('See All')}</Link>
        )}
      </Flex>
      <div>{children}</div>
    </div>
  );
};

export default SidebarCard;

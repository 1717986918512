/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Grid } from 'theme-ui';
import { ReactElement, useEffect } from 'react';
import SidebarCard from '../SidebarCard';
import { useDispatch, useSelector } from 'react-redux';
import { selectCategory } from 'reducers/category/selectors';
import { fetchCategories } from 'reducers/category/actions';
import CategoryCard from 'components/CategoryCard';

const SidebarCategories = ({ limit = 3 }): ReactElement => {
  const dispatch = useDispatch();
  const { categoryLoading, categories } = useSelector(selectCategory);

  useEffect(() => {
    if (!categories.length) {
      dispatch(fetchCategories());
    }
  }, []);

  const isLoading = () => categoryLoading && categories.length === 0;

  return (
    <SidebarCard left="Categories" url="/categories" loading={isLoading()}>
      <Grid columns={[1, 2, 3]}>
        {isLoading()
          ? [...new Array(3).keys()].map((i) => <CategoryCard key={i} />)
          : categories
              .slice(0, limit)
              .map((category) => (
                <CategoryCard key={category.id} category={category} />
              ))}
      </Grid>
    </SidebarCard>
  );
};

export default SidebarCategories;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Grid } from 'theme-ui';
import React, { useRef, useEffect } from 'react';
import HeadMeta from '../components/HeadMeta';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import SidebarCategories from '../components/Sidebar/SidebarCategories';
import SidebarAuthorsPopular from '../components/Sidebar/SidebarAuthorsPopular';
import SidebarSimilarPosts from '../components/Sidebar/SidebarSimilarPosts';
import SidebarAuthorSummary from '../components/Sidebar/SidebarAuthorSummary';
import { isOnMobile } from 'helpers/isOnMobile';
interface Props {
  error?: any;
  children?: React.ReactNode;
  title?: string;
  image?: string;
  description?: string;
  keywords?: string;
  url?: string;
  style?: any;
  cookies?: any;
  bodySx?: Record<string, string | number>;
  popularAuthors?: boolean;
  similarPosts?: Array<any>;
  author?: Record<string, string>;
  categories?: boolean;
  mt?: string;
  sideBar?: boolean;
  hideNavigation?: boolean;
}

const PageWrapper = ({
  children,
  error,
  author,
  popularAuthors,
  categories = true,
  similarPosts,
  bodySx = {},
  mt = '0px',
  sideBar = true,
  hideNavigation = false,
  ...props
}: Props): React.ReactElement => {
  const sidebarRef = useRef(null);
  const mainContainerRef = useRef(null);
  const sidebarContainerRef = useRef(null);

  const sidebarWidth = () => sidebarRef?.current?.offsetWidth;

  const handleOnScroll = () => {
    if (!mainContainerRef?.current || isOnMobile()) return;
    const node = mainContainerRef.current;
    const reachedFooter = node?.clientHeight - window?.pageYOffset < 570;
    if (reachedFooter) {
      sidebarContainerRef.current.style.maxHeight = 'calc(100vh - 280px)';
      return;
    }
    sidebarContainerRef.current.style.maxHeight = 'calc(100vh - 100px)';
  };

  useEffect(() => {
    window.addEventListener('scroll', handleOnScroll);
    return () => {
      window.removeEventListener('scroll', handleOnScroll);
    };
  }, []);

  return (
    <>
      <HeadMeta {...props} />
      {!hideNavigation && <Nav {...props} />}
      <main
        sx={{
          display: 'block',
          variant: 'containers.main',
          bg: '#E5E5E5',
          mt,
        }}
      >
        <Grid
          columns={[1, sideBar ? '1.8fr 1fr' : 1]}
          sx={{
            variant: 'containers.page',
            py: 3,
            position: 'relative',
          }}
        >
          <div ref={mainContainerRef} sx={bodySx}>
            {!error ? children : <h3>{error.message || error}</h3>}
          </div>

          <div ref={sidebarRef} sx={{ position: 'relative' }}>
            <div
              ref={sidebarContainerRef}
              sx={{
                position: ['relative', 'relative', 'fixed'],
                overflowY: ['auto', 'auto', 'scroll'],
                maxHeight: ['auto', 'auto', 'calc(100vh - 100px)'],
                zIndex: 1,
                width: ['auto', 'auto', sidebarWidth()],
                // '&::-webkit-scrollbar': {
                //   width: 0,
                //   background: 'transparent',
                // },
                // '&::-webkit-scrollbar-thumb':  {
                //   background: '#FF0000',
                // },
                borderRadius: 5,
              }}
            >
              {author && <SidebarAuthorSummary author={author} />}
              {!!similarPosts?.length && (
                <SidebarSimilarPosts posts={similarPosts} />
              )}
              {categories && <SidebarCategories />}
              {popularAuthors && <SidebarAuthorsPopular />}
            </div>
          </div>
        </Grid>
      </main>
      <Footer />
    </>
  );
};

export default PageWrapper;

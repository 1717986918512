/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import Link from 'next/link';
import { followAuthor } from 'reducers/author/actions';
import DefaultAvatar from 'assets/svg/default-avatar.svg';
import LoadingSkeleton from 'components/common/LoadingSkeleton';
import FollowButton from 'components/common/FollowButton';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectCurrentUser,
  selectFollowed,
} from 'reducers/currentUser/selectors';
import { setAuthModal } from 'redux/reducers/auth/actions';
import { useTranslation } from 'react-i18next';

interface Props {
  user?: {
    id?: number;
    firstName?: string;
    lastName?: string;
    avatarUrl?: string;
    postsCount?: number;
    admin?: boolean;
    username?: string;
    postsViewsCount?: number;
  };
}

const AuthorCard = ({ user = {} }: Props) => {
  const {
    firstName,
    lastName,
    avatarUrl,
    postsCount = 0,
    username,
    postsViewsCount = 0,
  } = user;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentUser } = useSelector(selectCurrentUser);
  const followed = useSelector(selectFollowed);

  const handleFollowAuthor = (e) => {
    e.preventDefault();
    if (!currentUser?.firstName) {
      dispatch(setAuthModal('Login'));
      return;
    }

    followAuthor({
      username: user?.username,
      followed: followed[user?.id],
      payload: {
        followedId: user?.id,
        createdAt: new Date(),
        userId: currentUser?.id,
        username: currentUser?.username,
        avatarUrl: currentUser?.avatarUrl,
        user: {
          username: currentUser?.username,
          avatarUrl: currentUser?.avatarUrl,
          firstName: currentUser?.firstName,
        },
      },
    })(dispatch);
  };

  return (
    <Link href={!username ? '#' : `/authors/${username}`}>
      <div
        sx={{
          backgroundColor: 'textWhite',
          overflow: 'hidden',
          borderRadius: 5,
          boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.5)',
        }}
      >
        <div
          sx={{
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
            '&:after': {
              content: '""',
              display: 'block',
              paddingBottom: '70%',
            },
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          {firstName ? (
            <img
              sx={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                objectFit: 'cover',
                objectPosition: 'center top',
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
              src={avatarUrl || DefaultAvatar}
              alt="user avatar"
            />
          ) : (
            <LoadingSkeleton
              style={{ position: 'absolute', height: '100%', width: '100%' }}
              height="100%"
              width="100%"
            />
          )}
        </div>
        <div
          sx={{
            mt: 1,
            px: 2,
            width: '100%',
            color: 'textBlack',
            '&:hover': {
              cursor: 'pointer',
            },
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontSize: 2,
          }}
        >
          {firstName || <LoadingSkeleton width="50px" />} {lastName}
        </div>
        <div
          sx={{
            color: 'textGray',
            '&:hover': {
              cursor: 'pointer',
            },
            mb: 1,
            px: 2,
            fontSize: 1,
          }}
        >
          {firstName ? (
            <>{t('{{postsCount}} Post(s)', { postsCount })}</>
          ) : (
            <LoadingSkeleton width="70px" />
          )}
        </div>
        {/* {postsViewsCount > 0 && (
          <div
            sx={{
              opacity: 0.5,
              '&:hover': {
                cursor: 'pointer',
              },
              mb: 1,
            }}
          >
            {firstName ? (
              <>
                {postsViewsCount} {t('Posts Views')}
              </>
            ) : (
              <LoadingSkeleton width="70px" />
            )}
          </div>
        )} */}
        <FollowButton
          disabled={currentUser?.id === user?.id}
          contentLoading={!firstName}
          followed={followed[user?.id]}
          onClick={handleFollowAuthor}
        />
      </div>
    </Link>
  );
};

export default AuthorCard;

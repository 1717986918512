/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { ReactElement } from 'react';
import { AiOutlineUserAdd, AiOutlineUserDelete } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/Button';
import LoadingSkeleton from 'components/common/LoadingSkeleton';

const activeStyles = {
  bg: 'textWhite',
  color: 'primary',
  borderColor: 'primary',
};
const FollowButton = ({
  followed,
  onClick,
  borderRadius = '0px 0px 5px 5px',
  contentLoading = false,
  inline = false,
  ...props
}: {
  followed?: boolean;
  loading?: boolean;
  disabled?: boolean;
  borderRadius?: string;
  contentLoading?: boolean;
  onClick?: (e?: any) => void;
  inline?: boolean;
}): ReactElement => {
  const { t } = useTranslation();
  return contentLoading ? (
    <LoadingSkeleton />
  ) : (
    <Button
      className={followed ? 'active' : ''}
      {...props}
      loading={!props.disabled ? props.loading : false}
      onClick={onClick}
      sx={{
        // display: inline ? 'inline-flex' : 'flex',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius,
        borderWidth: '1px',
        borderThick: 'solid',
        borderColor: 'transparent',
        borderStyle: 'solid',
        px: 3,
        py: '7px',
        fontSize: '15px',
        color: 'textWhite',
        bg: 'primary',
        fontWeight: 300,
        minWidth: '100px',
        '&:hover': {
          opacity: 0.96,
          cursor: 'pointer',
        },
        '&.active': activeStyles,
        width: inline ? 'auto' : '100%',
        '&:disabled': {
          cursor: 'not-allowed',
          opacity: 0.2,
        },
      }}
    >
      {followed ? (
        <>
          <AiOutlineUserDelete size="18" /> {t('Followed')}
        </>
      ) : (
        <>
          <AiOutlineUserAdd size="18" /> {t('Follow')}
        </>
      )}
    </Button>
  );
};

export default FollowButton;

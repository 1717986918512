/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { ReactElement } from 'react';
import { IoIosThumbsUp } from 'react-icons/io';
import LoadingSkeleton from 'components/common/LoadingSkeleton';

interface Props {
  loading?: boolean;
  onClick?: any;
  liked?: boolean;
  width?: string;
  height?: string;
  ml?: string;
}

const LikeButton = ({
  loading,
  height = '40px',
  width = '40px',
  ml = '0px',
  liked,
  ...props
}: Props): ReactElement => {
  return (
    <>
      <div
        sx={{
          height,
          width,
          ml,
          display: 'inline-flex',
          bg: 'textWhite',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          fontSize: '20px',
          color: 'rgba(0, 0, 0, 0.5)',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          '&:hover': {
            cursor: 'pointer',
            color: 'textGreen',
          },
        }}
        onClick={(e) => e.stopPropagation()}
        {...props}
      >
        {loading ? (
          <LoadingSkeleton circle={true} />
        ) : (
          <IoIosThumbsUp color={liked ? 'green' : 'inherit'} />
        )}
      </div>
    </>
  );
};

export default LikeButton;

import http from '../../../helpers/http';
import * as types from './types';

export const setLoading = (payload) => ({
  type: types.SET_LOADING,
  payload,
});

export const setError = (payload) => ({
  type: types.SET_ERROR,
  payload,
});

export const setAuthors = (payload) => ({
  type: types.SET_AUTHORS,
  payload,
});

export const setAuthorsPopular = (payload) => ({
  type: types.SET_AUTHORS_POPULAR,
  payload,
});

export const fetchAuthors = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { data } = await http.get('/authors');

    dispatch(setAuthors(data.authors));
  } catch (err) {
    // const { data } = err.response;
    dispatch(setAuthors([]));
  }
};

export const fetchAuthorsPopular = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { data } = await http.get('/authors/popular');

    dispatch(setAuthorsPopular(data.authorsPopular));
  } catch (err) {
    // const { data } = err.response;
    dispatch(setAuthorsPopular([]));
  }
};

export const fetchAuthor =
  ({ slug = '' }: { slug: string }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const { data } = await http.get(`/authors/${slug}`);
    } catch (err) {
      // const { data } = err.response;
    }
  };

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Grid } from 'theme-ui';
import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SidebarCard from '../SidebarCard';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthorPopular } from 'reducers/authorPopular/selectors';
import { fetchAuthorsPopular } from 'reducers/authorPopular/actions';
import AuthorCard from 'components/AuthorCard';

const SidebarCategories = (): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { authorPopularLoading, authorsPopular } =
    useSelector(selectAuthorPopular);

  useEffect(() => {
    if (!authorsPopular.length) {
      fetchAuthorsPopular()(dispatch);
    }
  }, []);

  return (
    <SidebarCard
      loading={authorPopularLoading}
      left={t('Popular Authors')}
      url="/authors"
    >
      <Grid columns={[1, 3]}>
        {authorPopularLoading && authorsPopular?.length === 0
          ? [...new Array(3).keys()].map((i) => <AuthorCard key={i} />)
          : authorsPopular?.map((user) => (
              <AuthorCard key={user.id} user={user} />
            ))}
      </Grid>
    </SidebarCard>
  );
};

export default SidebarCategories;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Grid } from 'theme-ui';
import { ReactElement } from 'react';
import SidebarCard from '../SidebarCard';
import PostCard from 'components/PostCard';

const SimilarPosts = ({ posts = [] }: { posts: Array<any> }): ReactElement => {
  return (
    <SidebarCard left="Similar Posts">
      <Grid columns={[1]}>
        {posts?.map((post) => (
          <PostCard sidebar key={post.id} post={post} />
        ))}
      </Grid>
    </SidebarCard>
  );
};

export default SimilarPosts;

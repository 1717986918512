/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, Flex } from 'theme-ui';
import DefaultAvatar from 'assets/svg/default-avatar.svg';
import Link from 'next/link';
import { useSelector, useDispatch } from 'react-redux';
import { followAuthor } from 'reducers/author/actions';
import LoadingSkeleton from 'components/common/LoadingSkeleton';
import FollowButton from 'components/common/FollowButton';
import {
  selectCurrentUser,
  selectFollowed,
} from 'reducers/currentUser/selectors';
import { setAuthModal } from 'redux/reducers/auth/actions';
import { useTranslation } from 'react-i18next';

interface Props {
  author?: Record<string, string>;
}

const AuthorSummaryCard = ({ author = {} }: Props) => {
  const {
    firstName,
    lastName,
    avatarUrl,
    postsCount = 0,
    bio,
    username,
  } = author;
  const dispatch = useDispatch();
  const { currentUser } = useSelector(selectCurrentUser);
  const followed = useSelector(selectFollowed);
  const { t } = useTranslation();

  const handleFollowAuthor = (e) => {
    e.preventDefault();
    if (!currentUser?.firstName) {
      dispatch(setAuthModal('Login'));
      return;
    }

    followAuthor({
      username: author?.username,
      followed: followed[author?.id],
      payload: {
        followedId: author?.id,
        createdAt: new Date(),
        userId: currentUser?.id,
        username: currentUser?.username,
        avatarUrl: currentUser?.avatarUrl,
        user: {
          username: currentUser?.username,
          avatarUrl: currentUser?.avatarUrl,
          firstName: currentUser?.firstName,
        },
      },
    })(dispatch);
  };

  return (
    <Link href={username ? `/authors/${username}` : '#'}>
      <Flex
        sx={{
          bg: 'textWhite',
          position: 'relative',
          borderRadius: 5,
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <div
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            borderRadius: 5,
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          {username ? (
            <img
              sx={{
                height: '100%',
                width: '110px',
                objectFit: 'cover',
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
              }}
              src={avatarUrl || DefaultAvatar}
              alt="user avatar"
            />
          ) : (
            <LoadingSkeleton
              style={{ borderRadius: 5 }}
              height="100%"
              width="110px"
            />
          )}
        </div>
        <div
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            pl: 2,
            '&:hover': {
              cursor: 'pointer',
            },
            width: '100%',
          }}
        >
          <div sx={{ mt: 2, variant: 'containers.ellipsis' }}>
            {firstName || <LoadingSkeleton width="60px" />}{' '}
            {lastName || <LoadingSkeleton width="60px" />}
          </div>
          <div sx={{ opacity: 0.5, fontSize: 2 }}>
            {firstName ? (
              <>
                {postsCount} {postsCount > 0 ? t('Posts') : t('Post')}
              </>
            ) : (
              <LoadingSkeleton width="60px" />
            )}
          </div>
          {firstName ? (
            <div
              sx={{
                my: 2,
                variant: 'containers.ellipsis',
                lineHeight: 1,
                WebkitLineClamp: 2,
                color: 'textGray',
              }}
            >
              {bio}
            </div>
          ) : (
            <LoadingSkeleton count={2} />
          )}
          <div sx={{ flex: 1, display: 'flex', alignItems: 'flex-end', mb: 2 }}>
            <FollowButton
              borderRadius="0px"
              disabled={currentUser?.id === author?.id}
              followed={followed[author?.id]}
              loading={!author?.username}
              onClick={handleFollowAuthor}
            />
          </div>
        </div>
      </Flex>
    </Link>
  );
};

export default AuthorSummaryCard;

export function isOnMobile() {
  if (typeof navigator === 'undefined') {
    return false;
  }
  const mobile = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];
  return mobile?.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ReactElement } from 'react';
import SidebarCard from '../SidebarCard';
// import { fetchSimiar } from 'reducers/post/actions';
import AuthorSummaryCard from 'components/AuthorCard/AuthorSummaryCard';

const AuthorSummary = ({
  author = {},
}: {
  author: Record<string, string>;
}): ReactElement => {
  return (
    <SidebarCard
      left="About the Author"
      leftUrl={`/authors/${author.username}`}
    >
      <AuthorSummaryCard author={author} />
    </SidebarCard>
  );
};

export default AuthorSummary;

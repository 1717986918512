/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import CategoryLabel from '../CategoryLabel';
import LikeButton from '../common/LikeButton';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import { setAuthModal } from 'redux/reducers/auth/actions';
import {
  selectCurrentUser,
  selectCategoryLiked,
} from 'reducers/currentUser/selectors';
import { likeCategory } from 'reducers/category/actions';

interface Props {
  category?: {
    id: number;
    slug: string;
    en: string;
    fr: string;
    coverImageUrl: string;
  };
}

const CategoryCard = ({ category }: Props) => {
  const { push } = useRouter();

  const dispatch = useDispatch();
  const { currentUser } = useSelector(selectCurrentUser);
  const likes = useSelector(selectCategoryLiked);

  const handleLike = (e) => {
    e.stopPropagation();

    if (!currentUser?.firstName) {
      dispatch(setAuthModal('login'));
      return;
    }
    likeCategory({
      slug: category?.slug,
      liked: !!likes[category?.id],
      payload: {
        createdAt: new Date(),
        categoryId: category?.id.toString(),
        userId: currentUser?.id,
      },
    })(dispatch);
  };

  return (
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '180px',
        backgroundColor: 'rgba(0, 0, 0, 0.075)',
        boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.09)',
        justifyContent: 'space-between',
        borderRadius: 5,
        p: 2,
        '&:hover': {
          cursor: 'pointer',
        },
        position: 'relative',
        overflow: 'hidden',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: `linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.15), rgba(0,0,0,0.5)), url("${category?.coverImageUrl}");`,
      }}
      onClick={() => {
        if (category?.slug) push(`/categories/${category?.slug}`);
      }}
    >
      {/* {category?.coverImageUrl && (
        <img
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            objectFit: 'cover',
            width: '100%',
            height: '100%',
          }}
          src={category?.coverImageUrl}
        />
      )} */}
      <div sx={{ zIndex: 1 }}>
        <CategoryLabel slug={category?.slug}>{category?.en}</CategoryLabel>
      </div>

      <Flex sx={{ justifyContent: 'flex-end', zIndex: 1 }}>
        <LikeButton
          liked={likes[category?.id]}
          onClick={handleLike}
          loading={!category?.slug}
        />
      </Flex>
    </div>
  );
};

export default CategoryCard;

import http from '../../../helpers/http';
import * as types from './types';
import { addCategoryLike, removeCategoryLike } from '../currentUser/actions';

export const setLoading = (payload) => ({
  type: types.SET_LOADING,
  payload,
});

export const setError = (payload) => ({
  type: types.SET_ERROR,
  payload,
});

export const setCategories = (payload) => ({
  type: types.SET_CATEGORIES,
  payload,
});

export const setCategory = (payload) => ({
  type: types.SET_CATEGORY,
  payload,
});

export const setCategoryLikes = (payload) => ({
  type: types.SET_CATEGORY_LIKES,
  payload,
});

export const addLike = (payload) => ({
  type: types.ADD_CATEGORY_LIKE,
  payload,
});

export const removeLike = (payload) => ({
  type: types.REMOVE_CATEGORY_LIKE,
  payload,
});

export const updateLikesCount = (payload) => ({
  type: types.UPDATE_LIKES_COUNT,
  payload,
});

export const fetchCategories = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { data } = await http.get('/categories');

    dispatch(setCategories(data.categories));
  } catch (err) {
    // const { data } = err.response;
    dispatch(setCategories([]));
  }
};

export const fetchCategory =
  ({ slug = '' }: { slug: string }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const { data } = await http.get(`/categories/${slug}`);
      dispatch(setCategory(data));
    } catch (err) {
      // const { data } = err.response;
    }
  };

export const fetchCategoryLikes =
  ({ slug = '' }: { slug: string }) =>
  async (dispatch) => {
    try {
      const { data } = await http.get(`/categories/${slug}/likes`);
      dispatch(setCategoryLikes(data));
    } catch (err) {
      // const { data } = err.response;
    }
  };

export const likeCategory =
  ({
    slug,
    liked = false,
    payload,
  }: {
    slug?: string;
    liked?: boolean;
    payload: any;
  }) =>
  async (dispatch) => {
    try {
      if (liked) {
        dispatch(removeCategoryLike(payload));
        dispatch(removeLike(payload));
      } else {
        dispatch(addCategoryLike(payload));
        dispatch(addLike(payload));
      }
      const { data } = await http[`${liked ? 'delete' : 'post'}`](
        `/categories/${slug}/likes`,
      );

      const counter = { count: liked ? -1 : 1, slug };

      dispatch(updateLikesCount(counter));

      return data;
    } catch (err) {
      // const { data } = err.response;
      const counter = { count: !liked ? -1 : 1, slug };
      dispatch(updateLikesCount(counter));
      if (!liked) {
        dispatch(removeCategoryLike(payload));
        dispatch(removeLike(payload));
      } else {
        dispatch(addCategoryLike(payload));
        dispatch(addLike(payload));
      }
    }
  };
